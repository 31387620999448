import { UserRole } from '@modules/auth/login/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: UserRole[] = [];

export const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    updateUserRole: (_state, action: PayloadAction<UserRole[]>) => {
      return action.payload;
    }
  }
});

export const { updateUserRole } = userRoleSlice.actions;

export default userRoleSlice.reducer;
