import { GenericApiResponse } from '@type/general';
import { ApiTags } from '@constants/common';
import restApi from '@services/api';

import {
  TransactionsListPayload,
  TransactionsListResponse
} from '../transactions-section/types';
import { tableHeaderMapper } from '../transactions-section/constants';
import {
  ConfirmPaymentRequestType,
  ConfirmRefundRequestType,
  LiveDealDetailsPayload,
  LiveDealDetailsResponse,
  LiveMoneyballDetailsResponse,
  TransactionHistoryPayload,
  TransactionHistoryResponse
} from './types';

const GetTransactionApi = restApi.injectEndpoints({
  endpoints: builder => ({
    // API: Attribution pending transactions list
    getAttributionPendingList: builder.query<
      TransactionsListResponse,
      TransactionsListPayload
    >({
      query: ({ limit, offset, search, sort, order }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/users/pending-payment-attribution?limit=${limit}&offset=${offset}&sort=${
          tableHeaderMapper[sort as keyof typeof tableHeaderMapper]
        }&order=${order}${
          search ? `&search=${encodeURIComponent(search)}` : ''
        }`,
        method: 'GET'
      }),
      providesTags: [ApiTags.getAttributionPendingList]
    }),

    // API: User transactions list
    getTransactionHistory: builder.query<
      TransactionHistoryResponse,
      TransactionHistoryPayload
    >({
      query: ({ userId }) => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/user/${userId}/payments/pending-attribution`,
        method: 'GET'
      }),
      transformResponse: (response: TransactionHistoryResponse) => response
    }),

    // API: Live deals list to attribute
    getLiveDealsDetails: builder.query<
      LiveDealDetailsResponse,
      LiveDealDetailsPayload
    >({
      query: ({ userId, deal_ids }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/user/${userId}/owndeals?deal_ids=${deal_ids.join(',')}`,
        method: 'GET'
      }),
      transformResponse: (response: LiveDealDetailsResponse) => response
    }),

    // API: Live Moneyball list to attribute
    getLiveMoneyballDetails: builder.query<
      LiveMoneyballDetailsResponse,
      LiveDealDetailsPayload
    >({
      query: ({ userId, deal_ids }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/user/${userId}/own-moneyballs?moneyball_ids=${deal_ids.join(
          ','
        )}`,
        method: 'GET'
      }),
      transformResponse: (response: LiveMoneyballDetailsResponse) => response
    }),

    // API: Investment confirmation
    confirmPayment: builder.mutation<
      GenericApiResponse<string>,
      ConfirmPaymentRequestType
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/utils/payment/manual/confirm`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: res => (res ? [ApiTags.getAttributionPendingList] : []),
      transformResponse: (response: GenericApiResponse<string>) => response
    }),

    //API: Create refund confirmation
    confirmRefund: builder.mutation<
      GenericApiResponse<string>,
      ConfirmRefundRequestType
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/utils/payment/refund`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: res => (res ? [ApiTags.getAttributionPendingList] : []),
      transformResponse: (response: GenericApiResponse<string>) => response
    })
  })
});

export const {
  useLazyGetAttributionPendingListQuery,
  useGetAttributionPendingListQuery,
  useLazyGetTransactionHistoryQuery,
  useLazyGetLiveDealsDetailsQuery,
  useConfirmPaymentMutation,
  useConfirmRefundMutation,
  useLazyGetLiveMoneyballDetailsQuery
} = GetTransactionApi;
