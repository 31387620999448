import { ReducerStateType } from '@modules/transaction/transactions-section/types';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  offset: 0,
  limit: 25,
  search: '',
  order: 'desc',
  sortBy: 'createdAt',
  clearPage: true,
  totalCount: 0
} as ReducerStateType;

export const transactionsRefundListSlice = createSlice({
  name: 'transactionsRefundListing',
  initialState,
  reducers: {
    resetTransactionRefundListingValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      search: '',
      order: 'desc',
      sortBy: 'createdAt',
      totalCount: 0
    }),
    updateTransactionRefundListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const {
  resetTransactionRefundListingValues,
  updateTransactionRefundListingValues
} = transactionsRefundListSlice.actions;

export default transactionsRefundListSlice.reducer;
