import { FieldErrors, FieldValues } from 'react-hook-form';
import { isEmpty } from 'lodash';

export function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  stringComparator?: (strA: string, strB: string) => number
): number {
  if (
    stringComparator &&
    typeof a[orderBy] === 'string' &&
    typeof b[orderBy] === 'string'
  ) {
    return stringComparator(
      a[orderBy] as unknown as string,
      b[orderBy] as unknown as string
    );
  }
  if (a[orderBy] > b[orderBy]) {
    return -1;
  }
  if (a[orderBy] < b[orderBy]) {
    return 1;
  }
  return 0;
}

export const findErrorTabIndex = <T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldToTabMapper: {
    [x: string]: string[];
  }
) => {
  let tabToRedirect = -1;
  if (!isEmpty(errors)) {
    for (const fieldName of Object.keys(errors)) {
      for (const tabIndex of Object.keys(fieldToTabMapper)) {
        if (fieldToTabMapper[tabIndex]?.includes(fieldName)) {
          tabToRedirect = Number(tabIndex);
          break;
        }
      }
      if (tabToRedirect > -1) {
        break;
      }
    }
  }
  return tabToRedirect;
};

export const convertStringToSnakeCase = (input: string) =>
  input
    .replace(/([a-z\d])([A-Z])/g, '$1_$2')
    .replace(/\s+/g, '_')
    .toLowerCase();

export const snakeCaseToNormal = (input: string): string => {
  // Split the string into words using underscores as separators
  const words = input.split('_');

  // Capitalize the first letter of each word and join them with spaces
  const result = words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return result;
};

export const getNumbersOnly = (input: string) => {
  const numericOnly = input.replace(/[^0-9]/g, '');
  return numericOnly;
};

export const getDecimalNumbersOnly = (input: string) => {
  const numericOnly = input.replace(/[^0-9.]/g, '');
  return numericOnly;
};

export const calculateOutputSize = (
  originalWidth: number,
  originalHeight: number,
  outputAspectRatio: number
) => {
  // Calculate the aspect ratio of the original image
  const originalAspectRatio = originalWidth / originalHeight;

  // Initialize the output width and height
  let outputWidth: number;
  let outputHeight: number;

  // Compare the aspect ratios and calculate the output dimensions accordingly
  if (originalAspectRatio > outputAspectRatio) {
    // Original image is wider than the desired aspect ratio
    outputWidth = Math.floor(originalHeight * outputAspectRatio);
    outputHeight = originalHeight;
  } else {
    // Original image is taller or has the same aspect ratio as desired
    outputWidth = originalWidth;
    outputHeight = Math.floor(originalWidth / outputAspectRatio);
  }

  return { outputWidth, outputHeight };
};

const isDecimal = (number: number) => {
  if (Number.isInteger(number)) {
    return false;
  }
  return true;
};

export const isLetter = (str: string) => {
  return str.match(/[a-z]/i);
};

export const isDigit = (str: string) => {
  return str.length === 1 && str.match(/[0-9]/i);
};

export const replaceCharAt = (str: string, index: number, char: string) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + char + str.substring(index + 1);
};

export const formatNumber = (number: number) => {
  if (isNaN(number)) {
    return '';
  }

  if (number >= 100000000000000) {
    if (isDecimal(number / 100000000000000)) {
      return `₹${(number / 100000000000000).toFixed(2)}Cr Cr`;
    }
    return `₹${number / 100000000000000}Cr Cr`;
  } else if (number >= 1000000000000) {
    if (isDecimal(number / 1000000000000)) {
      return `₹${(number / 1000000000000).toFixed(2)}L Cr`;
    }
    return `₹${number / 1000000000000}L Cr`;
  } else if (number >= 10000000000) {
    if (isDecimal(number / 10000000000)) {
      return `₹${(number / 10000000000).toFixed(2)}K Cr`;
    }
    return `₹${number / 10000000000}K Cr`;
  } else if (number >= 10000000) {
    if (isDecimal(number / 10000000)) {
      return `₹${(number / 10000000).toFixed(2)}Cr`;
    }
    return `₹${number / 10000000}Cr`;
  } else if (number >= 100000) {
    if (isDecimal(number / 100000)) {
      return `₹${(number / 100000).toFixed(2)}L`;
    }
    return `₹${number / 100000}L`;
  } else if (number >= 1000) {
    if (isDecimal(number / 1000)) {
      return `₹${(number / 1000).toFixed(2)}K`;
    }
    return `₹${number / 1000}K`;
  }
  return number.toString();
};

export const sortArrayByDesiredOrder = <T>(
  array: T[],
  keyToCompare: keyof T,
  desiredOrder: T[keyof T][]
) => {
  const arrayToSort = [...array];
  arrayToSort.sort((a, b) => {
    const indexA = desiredOrder.indexOf(a[keyToCompare]);
    const indexB = desiredOrder.indexOf(b[keyToCompare]);
    return indexA - indexB;
  });
  return arrayToSort;
};

export const generateFileUrl = (path: string, file: File | undefined) => {
  return path.includes('https')
    ? path
    : URL.createObjectURL(file || new File([], ''));
};

export const getFileNameFromUrl = (url: string) => {
  const path = url.split('/');
  return path[path.length - 1] || '';
};

export const handleDownloadFile = (fileUrl: string) => {
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);

      const pathname = new URL(fileUrl).pathname;
      const fileName = decodeURIComponent(
        pathname.substring(pathname.lastIndexOf('/') + 1)
      );

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
};

export const RGBAToHexA = (R: number, G: number, B: number, A: number) => {
  let r = R.toString(16);
  let g = G.toString(16);
  let b = B.toString(16);
  let a = Math.round(A * 255).toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;
  if (a.length == 1) a = '0' + a;

  return '#' + r + g + b + a;
};

export const extractAlphanumeric = (inputString: string) => {
  const alphanumericRegex = /[a-zA-Z0-9]/g;
  const matches = inputString.match(alphanumericRegex);
  const result = matches ? matches.join('') : '';

  return result;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
    timeZone: 'Asia/Kolkata'
  });
};

export const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const formatToIndianRupee = (
  amount: number,
  fractionDigits: number = 0,
  showCurrency: boolean = true
) => {
  return new Intl.NumberFormat('en-IN', {
    style: showCurrency ? 'currency' : 'decimal',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits
  }).format(amount);
};

export const hexToRgba = (hex: string, alpha: number) => {
  const hexValue = hex.replace(/^#/, '');
  let r = parseInt(hexValue.substring(0, 2), 16);
  let g = parseInt(hexValue.substring(2, 4), 16);
  let b = parseInt(hexValue.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const formatDecimalAmount = (amount: string) => {
  const sanitizedAmount = amount.replace(/[^0-9.]/g, '');
  const parts = sanitizedAmount.split('.');
  if (parts.length > 2) {
    return '';
  }
  let integerPart = parts[0]?.replace(/^0+/, '') || '0';
  let decimalPart = parts[1] !== undefined ? '.' + parts[1].slice(0, 2) : '';

  const lastThree = integerPart?.slice(-3);
  const otherNumbers = integerPart?.slice(0, -3);
  const formattedIntegerPart =
    otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
    (otherNumbers ? ',' : '') +
    lastThree;

  return formattedIntegerPart + decimalPart;
};

export enum UserRoles {
  SUPPORT = 'support',
  REFUND_CHECKER = 'refund-checker'
}
