import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';

import { DropDownIcon, EllipsisIcon } from '@assets/icons';
import { colors } from '@constants/colors';
import useOnClickOutside from '@hooks/useOnOutsideClick';

import { ActionButtonPropsType } from './types';

export interface ActionButtonRef {
  handleClose: () => void;
}

const ActionButton = forwardRef(
  (props: ActionButtonPropsType, ref: Ref<ActionButtonRef>) => {
    const {
      label,
      options,
      renderEllipsis = false,
      handleMenuItemClick,
      variant = 'outlined',
      hideDivider = false,
      isDisabled = false,
      returnMenuItemsOnly = false,
      customClassName,
      disablePortal = true,
      customEllipsisIconMainStyle = '',
      customEllipsisStyle
    } = props;

    const anchorRef = useRef(null);
    const outsideClickRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);

    const onClickButton = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation();
      setIsOpen(open => !open);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    const handleMenuClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      optionId: string
    ) => {
      event.stopPropagation();
      setIsOpen(false);
      handleMenuItemClick(optionId);
    };

    useImperativeHandle(ref, () => ({
      handleClose
    }));

    useOnClickOutside(outsideClickRef, () => handleClose());

    const menuItemsList = (
      <ClickAwayListener onClickAway={handleClose}>
        <MenuList id="split-button-menu" autoFocusItem sx={{ padding: '0px' }}>
          {options.map((option, index) => (
            <div key={index}>
              <MenuItem
                sx={{
                  color: option.labelColor ? option.labelColor : colors.black,
                  fontSize: '14px'
                }}
                onClick={event => handleMenuClick(event, option.id)}
                disabled={option.isDisabled}>
                {option.label}
              </MenuItem>
              {index === options.length - 2 && !hideDivider && (
                <Divider sx={{ margin: '2px !important' }} />
              )}
            </div>
          ))}
        </MenuList>
      </ClickAwayListener>
    );

    if (returnMenuItemsOnly) return menuItemsList;

    return (
      <div ref={outsideClickRef} className={customClassName}>
        {renderEllipsis ? (
          <Button
            ref={anchorRef}
            onClick={onClickButton}
            className="relative cursor-pointer"
            disabled={isDisabled}>
            <div className={`py-2 ${customEllipsisIconMainStyle}`}>
              <EllipsisIcon className={customEllipsisStyle} />
            </div>
          </Button>
        ) : (
          <ButtonGroup
            variant={variant}
            ref={anchorRef}
            aria-label="split button">
            <Button onClick={onClickButton} disabled={isDisabled}>
              {label}
            </Button>
            <Button
              size="small"
              aria-controls={isOpen ? 'split-button-menu' : undefined}
              aria-expanded={isOpen ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={onClickButton}
              disabled={isDisabled}>
              <DropDownIcon
                fill={
                  variant === 'contained' ? colors.white : colors.primaryBlue
                }
              />
            </Button>
          </ButtonGroup>
        )}
        <Popper
          sx={{
            zIndex: 1
          }}
          open={isOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal={disablePortal}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}>
              <Paper>{menuItemsList}</Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
);

export default ActionButton;
