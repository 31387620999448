import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { BreadcrumbsData } from '@components/breadcrumbs/types';
import { translate } from '@utils/locale';

import routesPath from '@routes/RoutesPath';

export enum BreadCrumPathKeys {
  DEAL = 'deal',
  DEAL_CREATE = 'dealCreate',
  DEAL_EDIT = 'dealEdit',
  DEAL_ANALYTICS = 'dealAnalytics',
  COMPANIES = 'companies',
  COMPANY_CREATE = 'companyCreate',
  COMPANY_EDIT = 'companyEdit',
  SYNDICATE = 'syndicate',
  SYNDICATE_CREATE = 'syndicateCreate',
  SYNDICATE_EDIT = 'syndicateEdit',
  USERS = 'users',
  USER_DETAILS = 'userDetails',
  USER_APP_INVITE = 'usersAppInvite',
  USERS_VERIFY = 'usersVerify',
  OPPORTUNITIES = 'opportunities',
  WISHLIST_CREATE = 'wishlistCreate',
  WISHLIST_EDIT = 'wishlistEdit',
  WISHLIST_VIEW = 'wishlistView',
  EARLY_ACCESS_CREATE = 'earlyAccessCreate',
  EARLY_ACCESS_EDIT = 'earlyAccessEdit',
  EARLY_ACCESS_VIEW = 'earlyAccessView',
  SCREENING = 'screening',
  VIEW_OVERALL_SUBSCRIPTION_STATUS = 'overallSubscriptionStatus',
  VIEW_OVERALL_WISHLISTED_USERS = 'overallWishlistedUsers',
  TRANSACTIONS = 'transactions'
}

const useBreadCrumbs = () => {
  const navigate = useNavigate();

  const getBreadCrumKeysFromPath = (path: string) => {
    if (_.intersection(path.split('/'), ['deals', 'create']).length > 1) {
      return BreadCrumPathKeys.DEAL_CREATE;
    }
    if (_.intersection(path.split('/'), ['deals', 'edit']).length > 1) {
      return BreadCrumPathKeys.DEAL_EDIT;
    }
    if (_.intersection(path.split('/'), ['deals', 'analytics']).length > 1) {
      return BreadCrumPathKeys.DEAL_ANALYTICS;
    }
    if (_.intersection(path.split('/'), ['companies', 'edit']).length > 1) {
      return BreadCrumPathKeys.COMPANY_EDIT;
    }
    if (_.intersection(path.split('/'), ['companies', 'create']).length > 1) {
      return BreadCrumPathKeys.COMPANY_CREATE;
    }
    if (_.intersection(path.split('/'), ['companies']).length === 1) {
      return BreadCrumPathKeys.COMPANIES;
    }
    if (_.intersection(path.split('/'), ['deals']).length === 1) {
      return BreadCrumPathKeys.DEAL;
    }
    if (_.intersection(path.split('/'), ['syndicate', 'create']).length > 1) {
      return BreadCrumPathKeys.SYNDICATE_CREATE;
    }
    if (_.intersection(path.split('/'), ['syndicate', 'edit']).length > 1) {
      return BreadCrumPathKeys.SYNDICATE_EDIT;
    }
    if (_.intersection(path.split('/'), ['syndicate']).length === 1) {
      return BreadCrumPathKeys.SYNDICATE;
    }
    if (_.intersection(path.split('/'), ['users', 'details']).length > 1) {
      return BreadCrumPathKeys.USER_DETAILS;
    }
    if (_.intersection(path.split('/'), ['users', 'app-invite']).length > 1) {
      return BreadCrumPathKeys.USER_APP_INVITE;
    }
    if (_.intersection(path.split('/'), ['users']).length === 1) {
      return BreadCrumPathKeys.USERS;
    }
    if (_.intersection(path.split('/'), ['verify']).length === 1) {
      return BreadCrumPathKeys.USERS_VERIFY;
    }
    if (
      _.intersection(path.split('/'), ['opportunities', 'wishlist', 'create'])
        .length > 2
    ) {
      return BreadCrumPathKeys.WISHLIST_CREATE;
    }
    if (
      _.intersection(path.split('/'), ['opportunities', 'wishlist', 'edit'])
        .length > 2
    ) {
      return BreadCrumPathKeys.WISHLIST_EDIT;
    }
    if (
      _.intersection(path.split('/'), [
        'opportunities',
        'wishlist',
        'view',
        'overall-wishlisted-status'
      ]).length > 3
    ) {
      return BreadCrumPathKeys.VIEW_OVERALL_WISHLISTED_USERS;
    }
    if (
      _.intersection(path.split('/'), ['opportunities', 'wishlist', 'view'])
        .length > 2
    ) {
      return BreadCrumPathKeys.WISHLIST_VIEW;
    }
    if (
      _.intersection(path.split('/'), [
        'opportunities',
        'early-access',
        'create'
      ]).length > 2
    ) {
      return BreadCrumPathKeys.EARLY_ACCESS_CREATE;
    }
    if (
      _.intersection(path.split('/'), ['opportunities', 'early-access', 'edit'])
        .length > 2
    ) {
      return BreadCrumPathKeys.EARLY_ACCESS_EDIT;
    }

    if (
      _.intersection(path.split('/'), [
        'opportunities',
        'early-access',
        'view',
        'overall-subscription-status'
      ]).length > 3
    ) {
      return BreadCrumPathKeys.VIEW_OVERALL_SUBSCRIPTION_STATUS;
    }
    if (
      _.intersection(path.split('/'), ['opportunities', 'early-access', 'view'])
        .length > 2
    ) {
      return BreadCrumPathKeys.EARLY_ACCESS_VIEW;
    }
    if (_.intersection(path.split('/'), ['opportunities']).length === 1) {
      return BreadCrumPathKeys.OPPORTUNITIES;
    }
    if (_.intersection(path.split('/'), ['screening']).length === 1) {
      return BreadCrumPathKeys.SCREENING;
    }
    if (_.intersection(path.split('/'), ['transactions']).length === 1) {
      return BreadCrumPathKeys.TRANSACTIONS;
    }
    return BreadCrumPathKeys.DEAL;
  };

  const breadCrumbsConfig = {
    [BreadCrumPathKeys.DEAL]: {
      label: translate('deal'),
      handleClick: () => navigate(routesPath.DEALS),
      parent: undefined
    },
    [BreadCrumPathKeys.DEAL_CREATE]: {
      label: translate('deals.newDeal'),
      parent: BreadCrumPathKeys.DEAL,
      handleClick: undefined
    },

    [BreadCrumPathKeys.DEAL_EDIT]: {
      label: translate('deals.editDeal'),
      parent: BreadCrumPathKeys.DEAL,
      handleClick: undefined
    },
    [BreadCrumPathKeys.DEAL_ANALYTICS]: {
      label: 'dealAnalytics',
      parent: BreadCrumPathKeys.DEAL,
      handleClick: undefined
    },
    [BreadCrumPathKeys.COMPANIES]: {
      label: translate('companies'),
      handleClick: () => navigate(routesPath.COMPANY),
      parent: undefined
    },
    [BreadCrumPathKeys.COMPANY_CREATE]: {
      label: translate('company.newCompany'),
      handleClick: undefined,
      parent: BreadCrumPathKeys.COMPANIES
    },
    [BreadCrumPathKeys.COMPANY_EDIT]: {
      label: translate('company.editCompany'),
      handleClick: undefined,
      parent: BreadCrumPathKeys.COMPANIES
    },
    [BreadCrumPathKeys.SYNDICATE]: {
      label: translate('syndicate.syndicate'),
      handleClick: () => navigate(routesPath.SYNDICATE),
      parent: undefined
    },
    [BreadCrumPathKeys.SYNDICATE_CREATE]: {
      label: translate('syndicate.newSyndicate'),
      handleClick: undefined,
      parent: BreadCrumPathKeys.SYNDICATE
    },
    [BreadCrumPathKeys.SYNDICATE_EDIT]: {
      label: translate('syndicate.editSyndicate'),
      handleClick: undefined,
      parent: BreadCrumPathKeys.SYNDICATE
    },
    [BreadCrumPathKeys.USERS]: {
      label: translate('navBar.users'),
      handleClick: () => navigate(routesPath.USER),
      parent: undefined
    },
    [BreadCrumPathKeys.USER_DETAILS]: {
      label: translate('users.details'),
      parent: BreadCrumPathKeys.USERS,
      handleClick: undefined
    },
    [BreadCrumPathKeys.USER_APP_INVITE]: {
      label: translate('users.appInvite.appInviteLog'),
      handleClick: undefined,
      parent: BreadCrumPathKeys.USERS
    },
    [BreadCrumPathKeys.USERS_VERIFY]: {
      label: translate('kycVerification.title'),
      parent: undefined
    },
    [BreadCrumPathKeys.OPPORTUNITIES]: {
      label: translate('opportunities.title'),
      handleClick: () => navigate(routesPath.OPPORTUNITIES),
      parent: undefined
    },
    [BreadCrumPathKeys.WISHLIST_CREATE]: {
      label: translate('opportunities.newWishlist'),
      parent: BreadCrumPathKeys.OPPORTUNITIES,
      handleClick: undefined
    },
    [BreadCrumPathKeys.WISHLIST_EDIT]: {
      label: translate('opportunities.editWishlist'),
      parent: BreadCrumPathKeys.OPPORTUNITIES,
      handleClick: undefined
    },
    [BreadCrumPathKeys.WISHLIST_VIEW]: {
      label: translate('opportunities.viewWishlist'),
      parent: BreadCrumPathKeys.OPPORTUNITIES,
      handleClick: undefined
    },
    [BreadCrumPathKeys.EARLY_ACCESS_CREATE]: {
      label: translate('opportunities.newEarlyAccess'),
      parent: BreadCrumPathKeys.OPPORTUNITIES,
      handleClick: undefined
    },
    [BreadCrumPathKeys.EARLY_ACCESS_EDIT]: {
      label: translate('opportunities.editEarlyAccess'),
      parent: BreadCrumPathKeys.OPPORTUNITIES,
      handleClick: undefined
    },
    [BreadCrumPathKeys.EARLY_ACCESS_VIEW]: {
      label: translate('opportunities.viewEarlyAccess'),
      parent: BreadCrumPathKeys.OPPORTUNITIES,
      handleClick: undefined
    },
    [BreadCrumPathKeys.SCREENING]: {
      label: translate('screeningTitle'),
      handleClick: () => navigate(routesPath.SCREENING),
      parent: undefined
    },
    [BreadCrumPathKeys.TRANSACTIONS]: {
      label: translate('transactionTitle'),
      handleClick: () => navigate(routesPath.TRANSACTIONS),
      parent: undefined
    },
    [BreadCrumPathKeys.VIEW_OVERALL_SUBSCRIPTION_STATUS]: {
      label: translate('opportunities.overallSubscription'),
      parent: BreadCrumPathKeys.EARLY_ACCESS_VIEW,
      handleClick: undefined
    },
    [BreadCrumPathKeys.VIEW_OVERALL_WISHLISTED_USERS]: {
      label: translate('opportunities.overallSubscription'),
      parent: BreadCrumPathKeys.WISHLIST_VIEW,
      handleClick: undefined
    }
  };

  const getBreadData = (
    path: BreadCrumPathKeys,
    iteration: number
  ): BreadcrumbsData[] => {
    const currentData = breadCrumbsConfig[path];
    const data = !iteration
      ? { ...currentData, handleClick: undefined }
      : currentData;
    if (currentData.parent) {
      return [data, ...getBreadData(currentData.parent, iteration + 1)];
    }
    return [data];
  };
  return {
    getBreadData,
    getBreadCrumKeysFromPath
  };
};

export default useBreadCrumbs;
