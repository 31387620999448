export const tableHeaderMapper = {
  userName: 'name',
  phoneNumber: 'phone_number',
  email: 'email',
  liveDeals: 'live_deals',
  virtualAccountBalance: 'virtual_account_balance',
  txnId: 'transaction_id',
  transactionAmount: 'transaction_amount',
  unattributedAmount: 'unattributed_amount',
  attributedAmount: 'attributed_amount',
  refundedAmount: 'refund_amount',
  createdAt: 'created_at',
  attributedTo: 'attributed_to',
  transactionDate: 'transaction_date'
};
