import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { isEmpty } from 'lodash';

import { DEFAULT_DATE_FORMAT } from '@constants/common';
import { colors } from '@constants/colors';

import { DatePickerProps } from './types';

const DatePicker: FC<DatePickerProps> = props => {
  const {
    name,
    control,
    placeholder,
    errors,
    defaultValue,
    wrapperClass = '',
    dateFormat = DEFAULT_DATE_FORMAT,
    isDisabled = false,
    isVerified = false,
    description,
    autoFocusField = false,
    showWhiteBackground = false,
    clearField = false
  } = props;

  const datePickerCustomStyles = {
    height: '52px',
    width: '100%',
    '& input': {
      height: '19px'
    },
    backgroundColor: showWhiteBackground ? 'white' : 'transparent',
    '& label.Mui-focused': {
      color: colors.primaryBlue
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: '#000000ab'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: isVerified ?? false ? colors.violet : colors.gray88,
        borderRadius: '5px',
        borderWidth: '1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primaryBlue,
        borderRadius: '5px',
        borderWidth: '1px'
      }
    }
  };

  const [newDate, setNewDate] = useState<Date | null>(
    defaultValue ? new Date(defaultValue) : null
  );

  useEffect(() => {
    if (defaultValue) {
      setNewDate(new Date(defaultValue));
    } else {
      setNewDate(null);
    }
  }, [defaultValue]);

  return (
    <div className={`w-full ${wrapperClass}`}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
              label={placeholder}
              openTo={'day'}
              views={['year', 'month', 'day']}
              value={newDate}
              onChange={newValue => {
                setNewDate(newValue);
                onChange(newValue);
              }}
              slotProps={{
                textField: {
                  error: !!error,
                  size: 'medium'
                },
                actionBar: {
                  actions: clearField ? ['clear'] : []
                }
              }}
              format={dateFormat}
              sx={datePickerCustomStyles}
              disabled={isDisabled}
              autoFocus={autoFocusField}
            />
          </LocalizationProvider>
        )}
      />
      {!isEmpty(errors) && (errors[name] as { message?: string })?.message ? (
        <p className="mx-[14px] mt-[3px] text-xs leading-5 text-red">
          {(errors[name] as { message?: string })?.message}
        </p>
      ) : (
        description && (
          // eslint-disable-next-line tailwindcss/no-custom-classname
          <p
            className={`mt-[3px] mx-[14px] leading-5 text-xs text-black ${
              isDisabled ? 'text-opacity-40' : 'text-opacity-60'
            }`}>
            {description}
          </p>
        )
      )}
    </div>
  );
};

export default DatePicker;
