import { translateDynamic } from '@utils/locale';
import {
  DealAccessType,
  LinkedInData,
  Questionnaire,
  ScreeningUser,
  UserProfilingStatus
} from './types';
import { ACCESS_CONTROL } from './components/access-control-actions/types';
import { ScreenUserFormFieldValues } from './container/types';
import { getDuration, getFullDuration } from '@utils/date';

const formatScreeningUsersListResponse = (users: ScreeningUser[]) => {
  return (
    users?.map(item => {
      const profileDataLinkedIn = item.profiling_data as LinkedInData;
      const profileDataQuestonire = item.profiling_data as Questionnaire;
      return {
        id: item.id,
        userName: item?.name ?? '',
        residence: item?.kyc_identity_type
          ? translateDynamic(`users.kycIdentityType.${item.kyc_identity_type}`)
          : '-',
        investorAccreditation: item?.investor_accreditation ?? '',
        age: item?.age ?? '',
        email: item?.email ?? '',
        workExp:
          item.profiling_mode === 'linkedin'
            ? getDuration(profileDataLinkedIn?.total_experience)
            : profileDataQuestonire?.work_experience,
        designation:
          item.profiling_mode === 'linkedin'
            ? profileDataLinkedIn?.occupation ?? ''
            : profileDataQuestonire?.current_role ?? '',
        dealName: item?.deal_names.join(',') ?? '',
        invitedBy: item?.invited_by ?? '',
        dealAccessType: item?.deal_access_type ?? '',
        status: item.profiling_status ?? ''
      };
    }) ?? []
  );
};

export const AccessControlStatusMap = (
  control: ACCESS_CONTROL
): UserProfilingStatus => {
  if (control === 'PASS') {
    return 'passed';
  }
  if (control === 'REJECT') {
    return 'rejected';
  }
  return 'waitlisted';
};

export const sortOrderKeyMap = (): {
  [key: string]: string;
} => {
  return {
    userName: 'name',
    residence: 'kyc_identity_type',
    investorAccreditation: 'investor_accreditation',
    age: 'age',
    email: 'email',
    dealAccessType: 'deal_access_type',
    startDate: 'start_date',
    profiling_status_last_updated: 'profiling_status_last_updated'
  };
};

export const formatLinkedInData = (data: ScreeningUser): LinkedInData => {
  const profileDataLinkedIn = data.profiling_data as LinkedInData;
  // const profileDataQuestonire = data.profiling_data as Questionnaire;
  const isLinkedInAvailable = data.profiling_mode === 'linkedin';
  const isFetchFailed = data.profiling_status === 'processing_failed';

  return {
    city:
      isLinkedInAvailable && !isFetchFailed ? profileDataLinkedIn?.city : '',
    connections:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.connections
        : 0,
    country:
      isLinkedInAvailable && !isFetchFailed ? profileDataLinkedIn?.country : '',
    education:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.education
        : [],
    first_name:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.first_name
        : '',
    follower_count:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.follower_count
        : 0,
    full_name:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.full_name
        : '',
    last_name:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.last_name
        : '',
    occupation:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.occupation
        : '',
    profile_picture:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.profile_picture
        : '',
    state:
      isLinkedInAvailable && !isFetchFailed ? profileDataLinkedIn?.state : '',
    summary:
      isLinkedInAvailable && !isFetchFailed ? profileDataLinkedIn?.summary : '',
    total_experience:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.total_experience
        : '',
    work_experience:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.work_experience
        : []
  };
};

export const formatLinkedInFormData = (
  data: ScreeningUser
): ScreenUserFormFieldValues => {
  const profileDataLinkedIn = data.profiling_data as LinkedInData;
  const profileDataQuestonire = data.profiling_data as Questionnaire;
  const isLinkedInAvailable = data.profiling_mode === 'linkedin';
  const isFetchFailed = data.profiling_status === 'processing_failed';

  return {
    accessType: formatAccessType(data.deal_access_type),
    currentlyWorkingAs:
      isLinkedInAvailable && !isFetchFailed
        ? profileDataLinkedIn?.occupation || ''
        : profileDataQuestonire?.current_role || '',
    isLinkedInAvailable: isLinkedInAvailable,
    workExperience:
      isLinkedInAvailable && !isFetchFailed
        ? getFullDuration(profileDataLinkedIn?.total_experience)
        : profileDataQuestonire?.work_experience || '',
    id: data.id
  };
};

export const formatAccessType = (access: DealAccessType) => {
  if (access === 'deal_lock') return 'Deal Lock';
  if (access === 'section_lock') return 'Section Lock';
  return 'Unlocked';
};

export { formatScreeningUsersListResponse };
