import { GenericApiResponse } from '@type/general';
import restApi from '@services/api';

import {
  DownloadScreeningListJobIdRes,
  DownloadScreeningListReq,
  GetScreeninglistDownloadUrlRes,
  ScreeningUser,
  ScreeningUsersListPayload,
  ScreeningUsersListResponse,
  UserProfilingStatus
} from './types';
import { ApiTags } from '@constants/common';
import { sortOrderKeyMap } from './service';

const ScreeningUserListApi = restApi.injectEndpoints({
  endpoints: builder => ({
    getScreeningUsersList: builder.query<
      ScreeningUsersListResponse,
      ScreeningUsersListPayload
    >({
      query: ({ limit, offset, search, sort, order, status }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/screening-users?limit=${limit}&offset=${offset}${
          search ? `&search=${encodeURIComponent(search)}` : ''
        }&sort=${sortOrderKeyMap()[sort]}&order=${order}&status=${status}`,
        method: 'GET'
      }),
      transformResponse: (response: ScreeningUsersListResponse) => response,
      providesTags: [ApiTags.userScreening]
    }),
    getScreeningUser: builder.query<
      GenericApiResponse<ScreeningUser>,
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/screening-users/${userId}`,
        method: 'GET'
      }),
      transformResponse: (response: GenericApiResponse<ScreeningUser>) =>
        response,
      providesTags: []
    }),
    getDownloadScreeningJobId: builder.mutation<
      DownloadScreeningListJobIdRes,
      DownloadScreeningListReq
    >({
      query: ({ search, sort, order, selectedTab }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/screening-users/export?${
          search ? `search=${encodeURIComponent(search)}&` : ''
        }${
          sort ? `sort=${sortOrderKeyMap()[sort]}&` : ''
        }order=${order}&status=${selectedTab}`,
        method: 'POST',
        body: {
          format: 'excel'
        }
      }),
      transformResponse: (response: DownloadScreeningListJobIdRes) => response
    }),
    getScreeningDowndloadUrl: builder.query<
      GetScreeninglistDownloadUrlRes,
      {
        jobId: string;
      }
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/screening-users/export/${payload.jobId}`,
        method: 'GET'
      }),
      transformResponse: (response: GetScreeninglistDownloadUrlRes) => response
    }),
    bulkUpdateUserProfillingStatus: builder.mutation<
      GenericApiResponse<ScreeningUser>,
      {
        userIds: string[];
        profilingStatus: UserProfilingStatus;
        comments: string | undefined;
      }
    >({
      query: ({ userIds, profilingStatus, comments }) => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/screening-users`,
        method: 'PATCH',
        body: {
          user_ids: userIds,
          profiling_status: profilingStatus,
          comments
        }
      }),
      invalidatesTags: res =>
        res?.status === 'ok' ? [ApiTags.userScreening] : [],
      transformResponse: (response: GenericApiResponse<ScreeningUser>) =>
        response
    })
  })
});

export const {
  useGetScreeningUsersListQuery,
  useLazyGetScreeningUsersListQuery,
  useGetScreeningUserQuery,
  useLazyGetScreeningUserQuery,
  useBulkUpdateUserProfillingStatusMutation,
  useGetDownloadScreeningJobIdMutation,
  useLazyGetScreeningDowndloadUrlQuery
} = ScreeningUserListApi;
