import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  unVerifiedDocsCount: number;
  pendingScreening: number;
  transactionCount: number;
} = {
  unVerifiedDocsCount: 0,
  pendingScreening: 0,
  transactionCount: 0
};

export const unVerifiedDocsCount = createSlice({
  name: 'unVerifiedDocsCount',
  initialState,
  reducers: {
    updateUnverifiedCount: (state, { payload }) => {
      state.unVerifiedDocsCount = payload;
    },
    updateScreeningCount: (state, { payload }) => {
      state.pendingScreening = payload;
    },
    updateTransactionCount: (state, { payload }) => {
      state.transactionCount = payload;
    }
  }
});

export const {
  updateUnverifiedCount,
  updateScreeningCount,
  updateTransactionCount
} = unVerifiedDocsCount.actions;

export default unVerifiedDocsCount.reducer;
